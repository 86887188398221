import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { FaTimes } from 'react-icons/fa';
import { Form, Row, Col } from 'react-bootstrap';
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

function ModalCadastrarCapacidade({ closeModal, keyArvore, setKeyArvore, descricaoArvore }) {

    const { getEmpresa, AdicionarCapacidade, Capacidades, Sistemas, Capacidade, setCapacidade, ListaArea, Areas, Pessoas, ListaPessoa } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [KeyPai, setKeyPai] = useState('')
    const [SistemasKey, setSistemasKey] = useState([])

    const [value, setValue] = useState(null);

    const handleChange = (event) => {
        setCapacidade({ ...Capacidade, [event.target.name]: event.target.value })
    }

    const onChange = (args) => {
        const selectedKeys = args.value.map(obj => obj.chave);
        setSistemasKey(selectedKeys);
    };

    let fields = { text: 'descricao', value: 'chave' };

    const salvarDemanda = () => {
        {
            keyArvore ?
                AdicionarCapacidade(keyArvore, SistemasKey)
                    .then(() => {
                        setCapacidade([])
                        setKeyArvore("")
                        closeModal(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                :
                AdicionarCapacidade(KeyPai, SistemasKey)
                    .then(() => {
                        setCapacidade([])
                        closeModal(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
        }
    }

    useEffect(() => {
        getEmpresa()
        ListaArea()
        ListaPessoa()
    }, [])

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <div>
                        <h1 className="h1-modal">Novo cadastro</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content">
                    <form className="mb-3">
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Título</Form.Label>
                                <Form.Control maxLength={100} onChange={handleChange} name="titulo" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col xs={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nível</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} name="nivel" />
                                </Form.Group>
                            </Col>
                            <Col xs={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Capacidade de Negócio Pai</Form.Label>
                                    {keyArvore ?
                                        <Form.Select name="capacidadeNegocioPaiKey">
                                            <option key={keyArvore} value={keyArvore}>
                                                {descricaoArvore}
                                            </option>
                                        </Form.Select>
                                        :
                                        <Form.Select onChange={(e) => setKeyPai(e.target.value)} name="capacidadeNegocioPaiKey">
                                            <option value="0">Selecione</option>
                                            {Capacidades?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.chaveCadastro}>
                                                        {item.descricao}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    }
                                </Form.Group>
                            </Col>
                            <Col xs={5}>
                                <Form.Label>Área</Form.Label>
                                <Form.Select onChange={handleChange} name="areaKey">
                                    <option value="0">Selecione</option>
                                    {Areas &&
                                        Areas?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave}>
                                                    {item.descricao}
                                                </option>
                                            )
                                        })}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} name="descricao" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Relevância</Form.Label>
                                    <Form.Select aria-label="Default select example" value={Capacidade.relevancia} onChange={handleChange} name="relevancia">
                                        <option value="">Selecione</option>
                                        <option value="Habilitação de negócios">Habilitação de negócios</option>
                                        <option value="Evolução de Negócios">Evolução de Negócios</option>
                                        <option value="Operação de negócios">Operação de negócios</option>
                                        <option value="Missão crítica">Missão crítica</option>
                                        <option value="Desconhecido">Desconhecido</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='mb-3'>
                                <div className='control-pane' style={{ marginTop: '5px' }}>
                                    <div id="multisection" className='control-section'>
                                        <div id="multidefault">
                                            <div className="control-styles">
                                                <div>
                                                    <label className="modal-label">Sistemas:</label>
                                                    <MultiSelectComponent id="mtselement" value={value} change={onChange.bind(this)} allowObjectBinding={true} dataSource={Sistemas} fields={fields} mode="Box" popupHeight="250px" popupWidth="250px" placeholder="Selecione..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label>Business Owner</Form.Label>
                                <Form.Select onChange={handleChange} name="pessoaKey">
                                    <option value="0">Selecione</option>
                                    {Pessoas &&
                                        Pessoas?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave}>
                                                    {item.nome}
                                                </option>
                                            )
                                        })}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Arquiteto corporativo</Form.Label>
                                <Form.Select onChange={handleChange} name="arquitetoKey">
                                    <option value="0">Selecione</option>
                                    {Pessoas &&
                                        Pessoas?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave}>
                                                    {item.nome}
                                                </option>
                                            )
                                        })}
                                </Form.Select>
                            </Col>
                        </Row>

                        {/* <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <input type="checkbox" label="Ativo" name='status' checked={Status} onChange={(e) => setStatus(e.target.checked)} className="toggleBtn" />
                            <p className="modal-status">Ativo</p>
                        </div> */}
                    </form>
                </div>
                <div className="footer-modal">
                    <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => salvarDemanda()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalCadastrarCapacidade