import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from '../../context/ApiContext';
import { DashboardContext } from '../../context/DashboardContext';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, ColumnSeries, DataLabel, Highlight } from '@syncfusion/ej2-react-charts';
import { FaStar, FaRegStar } from "react-icons/fa";
import { InputLabel, MenuItem, FormControl, Select, Skeleton } from '@mui/material'

export default function DemandasXCapacidades({ corFavorito, swotKey, tab }) {
    const theme = localStorage.getItem('theme') || 'light'
    const { AdicionarGraficoFavorito, DemandaXCapacidade, getDemandaXCapacidade } = useContext(ApiContext)
    const { qtdDemandaXCapacidade, setQtdDemandaXCapacidade } = useContext(DashboardContext)
    const [isLoading, setIsLoading] = useState(false)

    const axisLabelRender = (args) => {
        theme == 'light' ? args.labelStyle.color = '#fff' : args.labelStyle.color = '#3f3f46'
    };

    const primaryxAxis = { titleStyle: { color: theme == 'light' ? '#fff' : '#3f3f46' }, labelRotation: -45, labelIntersectAction: 'None', valueType: 'Category', interval: 1, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
    const primaryyAxis = { titleStyle: { color: theme == 'light' ? '#fff' : '#3f3f46' }, majorTickLines: { width: 0 }, lineStyle: { width: 0 } }

    useEffect(() => {
        if (swotKey != "") {
            setIsLoading(true)
            getDemandaXCapacidade(swotKey, qtdDemandaXCapacidade)
                .then(() => {
                    setIsLoading(false)
                })
        }
    }, [swotKey, qtdDemandaXCapacidade])

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', padding: '0' }}>
                <h2 className="h2-bsc">
                    <button onClick={() => AdicionarGraficoFavorito("Demandas x Capacidades de negócios")} className='btn-grafico'>{corFavorito("Demandas x Capacidades de negócios") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                    <span style={{ fontWeight: '500' }}>Demandas x Capacidades de negócios</span>
                </h2>
                <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="qtd-demanda">Quantidade de demandas</InputLabel>
                    <Select
                        className='span-bsc'
                        labelId="qtd-demanda-label"
                        id="qtd-demanda"
                        value={qtdDemandaXCapacidade}
                        label="Quantidade de demandas"
                        onChange={(e) => setQtdDemandaXCapacidade(parseInt(e.target.value))}
                    >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="1000">Todas</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="modal-content">
                <div style={{ width: '100%', minHeight: '550px' }}>
                    {isLoading ?
                        <Skeleton variant="rounded" width="100%" height={550} />
                        :
                        <ChartComponent id={`${tab}-demandaxcapacidade`} style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }}
                            primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} axisLabelRender={axisLabelRender} chartArea={{ border: { width: 0 } }} tooltip={{ enable: true, header: "<b>${point.tooltip}</b>", shared: true }} width={'100%'} title='' >
                            <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective dataSource={DemandaXCapacidade} tooltipMappingName='toolTipMappingName' xName='x' columnSpacing={0.01} yName='y' type='Column' />
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    }
                </div>
            </div>
        </>
    )
}