import '../../App.css';
import React, { useContext, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalEditarPlano() {
    const { EditarPlanoNegocio, EdicaoPlanoNegocio, setEdicaoPlanoNegocio, setModalEditarPlanoNegocio, TipoPlanoNegocio, PlanoNegocios } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [tipo, setTipo] = useState('')

    const handleChange = (event) => {
        setEdicaoPlanoNegocio({ ...EdicaoPlanoNegocio, [event.target.name]: event.target.value })
    }

    const handleTipo = (event) => {
        setEdicaoPlanoNegocio({ ...EdicaoPlanoNegocio, [event.target.name]: event.target.value })
        switch (event.target.value) {
            case "NUMERO":
                setTipo("number")
                break
            case "DATA":
                setTipo("date")
                break
            case "MOEDA":
                setTipo("number")
                break
            case "LISTA":
                setTipo("lista")
                break
            case "TEXTO":
                setTipo("text")
                break
        }
    }

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Editar plano de negócio</h1>
                    <div className="btn-close-modal" onClick={() => setModalEditarPlanoNegocio(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div>
                    <div className="modal-content">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Avaliação</Form.Label>
                                    <Form.Control maxLength={100} defaultValue={EdicaoPlanoNegocio.avaliacao} onChange={handleChange} name="avaliacao" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Indicador</Form.Label>
                                    <Form.Control maxLength={100} defaultValue={EdicaoPlanoNegocio.indicador} onChange={handleChange} name="indicador" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleTipo} name="tipo">
                                        <option value="0">Selecione</option>
                                        {TipoPlanoNegocio?.map((item, index) => {
                                            return (
                                                <option key={index} value={item} selected={item == EdicaoPlanoNegocio?.tipo ? true : false}>{item}</option>
                                            )

                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                {(tipo === "lista" || EdicaoPlanoNegocio?.tipo === "LISTA") ?
                                    <Form.Group className="mb-3">
                                        <Form.Label>Valor</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={EdicaoPlanoNegocio.valor} onChange={handleChange} name="valor">
                                            <option value="Selecione">Selecione</option>
                                            <option value="Muito baixo">Muito baixo</option>
                                            <option value="Baixo">Baixo</option>
                                            <option value="Médio">Médio</option>
                                            <option value="Alto">Alto</option>
                                            <option value="Muito alto">Muito alto</option>
                                        </Form.Select>
                                    </Form.Group>
                                    :
                                    <Form.Group className="mb-3">
                                        <Form.Label>Valor</Form.Label>
                                        <Form.Control type={tipo} maxLength={100} defaultValue={EdicaoPlanoNegocio.valor} onChange={handleChange} name="valor" />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Plano de Negócio Pai</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={handleChange} name="planoNegocioPaiKey">
                                    <option value="">Selecione</option>
                                    {PlanoNegocios?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.chave} selected={item.chave == EdicaoPlanoNegocio?.planoNegocioPai?.key ? true : false}>{item?.avaliacao}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditarPlanoNegocio(false)}>Cancelar</button>
                        <button type='button' className="btn-dash btn-criar" style={btnCriar} onClick={() => EditarPlanoNegocio()}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarPlano