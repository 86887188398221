import { createGlobalStyle } from "styled-components";

export const GlobalStyled = createGlobalStyle`

body {
    background-color: ${({ theme }) => theme.body};
}

h3 {
  color:${({ theme }) => theme.h3};
}

.header{
    background-color: ${({ theme }) => theme.header};
}

.btn-ver {
  border: 1px solid ${({ theme }) => theme.borderBtnVer};
  color:${({ theme }) => theme.textBtnVer};
}

.btn-criar {
  background-color: ${({ theme }) => theme.header};
}

.section-grafico{
    background-color: ${({ theme }) => theme.bgCard};
    color:${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.borderCard};
}

.section-bsc {
    background-color: ${({ theme }) => theme.bgCard};
    border: 1px solid ${({ theme }) => theme.borderCard};
    color:${({ theme }) => theme.textBlack};
}
.section-novo-bsc {
    background-color: ${({ theme }) => theme.bgCard};
    border: 1px solid ${({ theme }) => theme.borderCard};
    color:${({ theme }) => theme.textBlack};
}

.section-bsc-grafico {
    background-color: ${({ theme }) => theme.bgCard};
    border: 1px solid ${({ theme }) => theme.borderCard};
}

.td-bsc {
  border-bottom: 1px solid ${({ theme }) => theme.status};
}

.status {
  background-color: ${({ theme }) => theme.status};
  color: ${({ theme }) => theme.text};
}

.red {
  background-color: ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.redtext};
}

.green {
  background-color:${({ theme }) => theme.green};
  color: ${({ theme }) => theme.greentext};
}

.blue {
  background-color:${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.bluetext};
}

.item-fases {
    background-color: ${({ theme }) => theme.dashGrafico};
    box-shadow: ${({ theme }) => theme.borderCardGrafico};
    color:${({ theme }) => theme.text};
}

.icon-fases {
  color:${({ theme }) => theme.icone};
  background-color: ${({ theme }) => theme.status};
}

.titulo-fases {
  color:${({ theme }) => theme.text};
}

.qtd-fases {
  color:${({ theme }) => theme.text};
}

.card-fases {
  background-color: ${({ theme }) => theme.bgCard};
    border: 1px solid ${({ theme }) => theme.borderCard};
}

.content-prioridade {
  background-color: ${({ theme }) => theme.bgCard};
}

.card-investimento {
  background-color: ${({ theme }) => theme.status};
}

.item-linha {
    background-color: ${({ theme }) => theme.bgCard};
    border: 1px solid ${({ theme }) => theme.borderCard};
}

.p-descricao {
    color:${({ theme }) => theme.text};
}

.p-responsavel {
  color:${({ theme }) => theme.text};
}

.h1-dashboard {
    color:${({ theme }) => theme.textDescricao};
}

.header-modal-criar-demanda>h1, .header-modal>h1, .header-modal>div>.h1-modal, .header-modal-criar-demanda>div>h1 {
  background-color: ${({ theme }) => theme.status};
}

.h1-modal {
  color:${({ theme }) => theme.text};
}

.h1-sem-demandas {
  text-align: center;
  color:${({ theme }) => theme.text};
}

.h2-sem-demandas {
  line-height: 30px;
  text-align: center;
  font-size: 22px !important;
  color:${({ theme }) => theme.text};
}

.h2-dashboard {
    color:${({ theme }) => theme.textBlack};
}

.dashboard-right {
  border-left: 1px solid #dadada;
  color:${({ theme }) => theme.text};
}

.bm-menu {
  background: ${({ theme }) => theme.dashGrafico} !important;
}

.css-16jkw2k >.ps-menu-button {
    color:${({ theme }) => theme.textBlack} !important;
}

.css-16jkw2k >.ps-menu-button:hover {
  background-color: ${({ theme }) => theme.menuHover} !important;
  color:${({ theme }) => theme.textBlack};
}

.css-16jesut >.ps-menu-button {
    color:${({ theme }) => theme.textBlack} !important;
}

.css-16jesut >.ps-menu-button:hover {
  background-color: ${({ theme }) => theme.menuHover} !important;
  color:${({ theme }) => theme.textBlack} !important;
}

.css-12vkui9 >.ps-menu-button{
    color:${({ theme }) => theme.textBlack} !important;
}

.css-12vkui9 >.ps-menu-button:hover {
  background-color: ${({ theme }) => theme.menuHover} !important;
  color:${({ theme }) => theme.textBlack} !important;
}

.css-ewdv3l{
  background-color: ${({ theme }) => theme.dashGrafico} !important;
  color:${({ theme }) => theme.textBlack} !important;
}

#demandas_datalabel_Series_0_text_0, #demandas_datalabel_Series_0_text_1, #demandas_datalabel_Series_0_text_2 {
  fill: ${({ theme }) => theme.text};
}

#impacto_datalabel_Series_0_text_0, #impacto_datalabel_Series_0_text_1, #impacto_datalabel_Series_0_text_2 {
    fill: ${({ theme }) => theme.text};
}

#investimento_datalabel_Series_0_text_0, #investimento_datalabel_Series_0_text_1, #investimento_datalabel_Series_0_text_2, #investimento_datalabel_Series_0_text_3, #investimento_datalabel_Series_0_text_4,
#investimento_datalabel_Series_0_text_5, #investimento_datalabel_Series_0_text_6, #investimento_datalabel_Series_0_text_7, #investimento_datalabel_Series_0_text_8,
#investimento_datalabel_Series_0_text_9, #investimento_datalabel_Series_0_text_10, #investimento_datalabel_Series_0_text_11, #investimento_datalabel_Series_0_text_12,
#investimento_datalabel_Series_0_text_13, #investimento_datalabel_Series_0_text_14, #investimento_datalabel_Series_0_text_15, #investimento_datalabel_Series_0_text_16,
#investimento_datalabel_Series_0_text_17, #investimento_datalabel_Series_0_text_18, #investimento_datalabel_Series_0_text_19, #investimento_datalabel_Series_0_text_20,
#investimento_datalabel_Series_0_text_21, #investimento_datalabel_Series_0_text_22, #investimento_datalabel_Series_0_text_23, #investimento_datalabel_Series_0_text_24,
#investimento_datalabel_Series_0_text_25, #investimento_datalabel_Series_0_text_26, #investimento_datalabel_Series_0_text_23, #investimento_datalabel_Series_0_text_27,
#investimento_datalabel_Series_0_text_28, #investimento_datalabel_Series_0_text_29, #investimento_datalabel_Series_0_text_30, #investimento_datalabel_Series_0_text_31
{
    fill: ${({ theme }) => theme.text};
}

.h1-pages {
    color:${({ theme }) => theme.textDescricao};
}

.th-bsc {
  color:${({ theme }) => theme.text};
}

.content-bsc > tr {
    color:${({ theme }) => theme.textDescricao};
}

.content-value-bsc {
  border: 1px solid ${({ theme }) => theme.status};
}

.content-risco {
  background-color: ${({ theme }) => theme.status};
  color: ${({ theme }) => theme.icone};
}

.content-risco span {
  color: ${({ theme }) => theme.text};
}

.content-comentario {
  background-color: ${({ theme }) => theme.status};
}

#bsc_datalabel_Series_0_text_0, #bsc_datalabel_Series_0_text_1, #bsc_datalabel_Series_0_text_2 {
    fill: ${({ theme }) => theme.text};
}

.css-x02yi8-MuiTableCell-root {
    color: ${({ theme }) => theme.textBlack} !important;
}

.h1-demanda {
    color:${({ theme }) => theme.textDescricao};
}

.h1-light-demanda {
    color:${({ theme }) => theme.text};  
}

.footer {
  background-color: ${({ theme }) => theme.body};
  color:${({ theme }) => theme.textDescricao};
}

.footer-link {
  color:${({ theme }) => theme.textDescricao};
}

.footer-link:hover {
  color: ${({ theme }) => theme.ordem};
}

/* Inicio do modo dark da pagina Demandas */

.filtro-demanda {
  background-color: ${({ theme }) => theme.filtroDemanda};
  color: ${({ theme }) => theme.textBlack};
}

.h3-demanda {
  background-color: ${({ theme }) => theme.dashGrafico};
}

.tr-dem {
  background-color: ${({ theme }) => theme.dashGrafico};
  color: ${({ theme }) => theme.textBlack};
}

.e-grid .e-headercell {
 color:${({ theme }) => theme.textBlack};
 background-color: ${({ theme }) => theme.dashGrafico};
}

.e-grid .e-headercell:hover {
 color:${({ theme }) => theme.textBlack};
}

.e-grid .e-gridpager {
 background-color: ${({ theme }) => theme.body};
 color: ${({ theme }) => theme.textDescricao};
}

.e-grid .e-pagercontainer {
 background-color: ${({ theme }) => theme.body};
}

.e-grid th.e-headercell[aria-sort=ascending] .e-headertext {
  color: ${({ theme }) => theme.textBlack};
}

.e-grid th.e-headercell[aria-sort=descending] .e-headertext {
  color: ${({ theme }) => theme.textBlack};
}

.e-grid .e-gridpager .e-prevpagedisabled,
.e-grid .e-gridpager .e-prevpage,
.e-grid .e-gridpager .e-nextpage,
.e-grid .e-gridpager .e-nextpagedisabled,
.e-grid .e-gridpager .e-lastpagedisabled,
.e-grid .e-gridpager .e-lastpage,
.e-grid .e-gridpager .e-firstpage,
.e-grid .e-gridpager .e-firstpagedisabled {
  background-color: ${({ theme }) => theme.dashGrafico};
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
  color: ${({ theme }) => theme.textBlack};
}

.e-toolbar .e-toolbar-items {
  background-color: ${({ theme }) => theme.dashGrafico};
}


// .btn-dem:hover {
//   background-color: ${({ theme }) => theme.btnHover};
// }

.header-demanda{
  background: ${({ theme }) => theme.headerModal} !important;
}

.porcentagem {
  color: ${({ theme }) => theme.text};
}

.value-bsc {
  color: ${({ theme }) => theme.text};
}

.h2-bsc {
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.bgCard};
}

.btn-grafico {
  color: ${({ theme }) => theme.text};
  background-color: transparent;
  border: none;
  font-size: 22px;
  margin-right: 15px;
}

.span-bsc {
  color: ${({ theme }) => theme.text};
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  color: ${({ theme }) => theme.text};
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: 1px solid ${({ theme }) => theme.text};
}

#inicio-impacto_datalabel_Series_0_text_0, #inicio-impacto_datalabel_Series_0_text_1, #inicio-impacto_datalabel_Series_0_text_2,
#bsc-impacto_datalabel_Series_0_text_0, #bsc-impacto_datalabel_Series_0_text_1, #bsc-impacto_datalabel_Series_0_text_2,
#acc_chart_57724_1_datalabel_Series_0_text_0, #acc_chart_57724_1_datalabel_Series_0_text_1, #acc_chart_57724_1_datalabel_Series_0_text_2,
#swot-indice_AxisLabelsGroup_0>text, #swot-radarAxisLabels0>text, #swot-radarAxisLabels1>text,
#demandas-bscxswotxgutAxisLabels0>text, #demandas-bscxswotxgutAxisLabels1>text,
#lineargauge_control_1_AxisLabelsGroup_0>text, #chart-swotAxisLabels0>text, #chart-swotAxisLabels1>text
{
  fill: ${({ theme }) => theme.text} !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  background-color: ${({ theme }) => theme.bgCard};
  padding: 0 5px;
}

.header-prioridade {
  background: ${({ theme }) => theme.status};
  color: ${({ theme }) => theme.text};
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: ${({ theme }) => theme.text} !important;
  font-weight: bold;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: ${({ theme }) => theme.icone} !important;
}

.tooltip-bsc {
  background-color: ${({ theme }) => theme.bgBtnCriar};
}

.td-bsc-ordem {
  background-color: ${({ theme }) => theme.bgCard};
  color: ${({ theme }) => theme.text};
}

.p-title {
  color: ${({ theme }) => theme.icone};
}

.p-content {
  color: ${({ theme }) => theme.text};
}

.p-content a {
  color: ${({ theme }) => theme.text};
}

.div-link a {
  color: ${({ theme }) => theme.text};
}

/* Inicio do modo dark dos cards de cadastro */
.card{
  background-color:${({ theme }) => theme.dashGrafico} !important;
}

.card-header {
  background-color:${({ theme }) => theme.dashGrafico} !important;
  color: ${({ theme }) => theme.textBlack};
}

.e-toolbar .e-toolbar-items {
  color: ${({ theme }) => theme.textBlack};
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search{
  background-color:${({ theme }) => theme.dashGrafico} !important;
  color: ${({ theme }) => theme.textBlack} !important;
}

.e-grid .e-table {
    background-color: ${({ theme }) => theme.dashGrafico} !important;
}

.e-grid .e-table:hover {
    background-color: ${({ theme }) => theme.dashGrafico} !important;
}

.e-rowcell{
  color: ${({ theme }) => theme.textBlack} !important;
}

/* Inicio do modo dark modal editar*/
.modal-criar-demanda-interno {
  background-color: ${({ theme }) => theme.bgCard};
  color: ${({ theme }) => theme.text} !important;
}

.modal-nova-interno {
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text} !important; 
}

.modal-content-nova, .footer-modal-nova, .modal-swot {
  background-color: ${({ theme }) => theme.bgCard};
}

.e-list-text, .e-icons, .e-icon-expandable {
  color: ${({ theme }) => theme.text} !important;
}

.e-treeview .e-list-item.e-hover > .e-fullrow {
    background-color: ${({ theme }) => theme.btnHover} !important;
    border-color: ${({ theme }) => theme.btnHover} !important;
}

.modal-criar-ciclo-interno {
  background-color: ${({ theme }) => theme.bgCard};
  color: ${({ theme }) => theme.text} !important;
}

.container-btn-arvore {
background-color: ${({ theme }) => theme.bgCard};
}

.nav-tabs-demanda {
  background-color: ${({ theme }) => theme.bgCard} !important;
}

.form-control{
  background-color: ${({ theme }) => theme.dashGrafico} !important;
  color: ${({ theme }) => theme.text} !important;
}

.form-select{
  background-color: ${({ theme }) => theme.dashGrafico} !important;
  color: ${({ theme }) => theme.textBlack} !important;
}

.container-gut{
  background-color: ${({ theme }) => theme.containerGut} !important;
  color: ${({ theme }) => theme.letra} !important;
}

.modal-interno-delete{
  background-color: ${({ theme }) => theme.bgCard};
}

.border-bottom {
  border-bottom: 1px solid ${({ theme }) => theme.status};
  color: ${({ theme }) => theme.textBlack};
}

.h2-modal{
  color: ${({ theme }) => theme.textDescricao};
}

.e-control {
  color: ${({ theme }) => theme.textBlack} !important;
}

.e-input-group .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  color: ${({ theme }) => theme.textBlack} !important;
}

.e-date-wrapper {
  border: 1px solid ${({ theme }) => theme.borderInput} !important;
  background-color: ${({ theme }) => theme.dashGrafico} !important;
}

.modal-novo-interno{
  background-color: ${({ theme }) => theme.bgCard};
}

.nav-tabs-demanda .nav-link{
  color: ${({ theme }) => theme.textBlack} !important;
}

.nav-tabs-demanda .nav-link.active, .nav-tabs-bsc .nav-link.active{
  background-color: ${({ theme }) => theme.dashGrafico} !important;
  border: 1px solid ${({ theme }) => theme.borderCard};
  border-bottom: none;
}

.nav-tabs-bsc .nav-link.active{
  background-color: ${({ theme }) => theme.bgCard} !important;
}

.nav-fill .nav-item {
  background-color: ${({ theme }) => theme.borderCard} !important;
}

// .nav-tabs-bsc {
//   background-color: ${({ theme }) => theme.borderCard} !important;
// }

.modal-input{
  background-color: ${({ theme }) => theme.status} !important;
  color: ${({ theme }) => theme.text} !important;
}
.option-prioridade{
  background-color: ${({ theme }) => theme.labelCard};
  color: ${({ theme }) => theme.text} !important;
}
.select-prioridade{
  color: ${({ theme }) => theme.text} !important;
}

.form-label {
  color: ${({ theme }) => theme.text} !important;
}

.form-check {
  color: ${({ theme }) => theme.text} !important;
}

.modal-label {
  color: ${({ theme }) => theme.text} !important;
}

.modal-status {
  color: ${({ theme }) => theme.text} !important;
}

/*Início modo dark na página de ciclos*/
.tr-ciclo {
  color: ${({ theme }) => theme.text};
}

/*Itens da tabela*/

.item-ativo {
  color: ${({ theme }) => theme.txtAtivo};
}

.item-inativo {
  color: ${({ theme }) => theme.txtInativo};
}

.reorder {
  background-color: ${({ theme }) => theme.status};
}

.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  background-color: ${({ theme }) => theme.dashGrafico};
  color: ${({ theme }) => theme.textBlack}
}

.modal-rtf-interno {
  background-color: ${({ theme }) => theme.body};
}

.btn-editor {
  background-color: ${({ theme }) => theme.bgBtnCriar};
}

.input-descricao {
  background-color: ${({ theme }) => theme.dashGrafico};
  color: ${({ theme }) => theme.textBlack}
}

.e-rte-toolbar {
  background-color: ${({ theme }) => theme.dashGrafico};
}

.div-compartilhar {
  color: ${({ theme }) => theme.textBlack}
}

.btn-copy {
  color: ${({ theme }) => theme.textBlack}
}

.value-percentual {
  color: ${({ theme }) => theme.text} !important;
}

#bsc_Series_0_Point_0, #bsc_Series_0_Point_1, #bsc_Series_0_Point_2 {
  stroke: ${({ theme }) => theme.bgCard} !important;
}

.span-cinza {
color: ${({ theme }) => theme.icone}
}

`;
