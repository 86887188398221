import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { IoMdEye } from "react-icons/io";
import { FaPlus } from 'react-icons/fa'
import ModalCadastrarCapacidade from '../../components/modal/ModalCadastrarCapacidade';
import ModalArvoreCapacidade from '../../components/modal/ModalArvoreCapacidade';
import ModalEditarCapacidade from '../../components/modal/ModalEditarCapacidade';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { TbPencil } from "react-icons/tb";
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';

function CapacidadeNegocioDemanda() {
    const { Capacidades, modalEditarOpen, loading, ListaCapacidades, setModalEditar, setDemandaAtual, Success, Error, ListaSistemas, setCapacidadeAtualKey, setEdicaoCapacidade } = useContext(ApiContext)
    const toolbarOptions = ['Search'];

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });
    const { h3Demanda, corTerciaria, btnCriar, btnVer } = useContext(StyleContext)
    const [abreModal, setAbreModal] = useState(false)
    const [openModal, setopenModal] = useState(false)

    const EditCadastro = (props) => {
        setDemandaAtual(props)
        setModalEditar(true)
        setCapacidadeAtualKey(props.chaveCadastro)
        setEdicaoCapacidade(props)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-dem'>
            <td className="td-dem">{props.titulo ? props.titulo : "-"}</td>
            <td className="td-dem">
                {(props.ativo.toString() == 'true') ? (
                    <div className="item-ativo">⬤</div>
                ) : (
                    <div className="item-inativo">⬤</div>
                )}
            </td>
            <td className="td-dem">
                <button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => EditCadastro(props)}><TbPencil /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        ListaSistemas()
        ListaCapacidades("N")
    }, [])

    return (
        <>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <Header pagina={'Cadastros'} subpagina={'Capacidade Negócio Demanda'}></Header>
            <MenuLateral></MenuLateral>
            <div className="container-principal">
                <div className="demanda-header">
                    {openModal &&
                        <ModalCadastrarCapacidade closeModal={setopenModal} />
                    }
                    {abreModal &&
                        <ModalArvoreCapacidade setAbreModal={setAbreModal} />
                    }
                    <button className='btn-dash btn-ver' style={btnVer} onClick={setAbreModal}><IoMdEye style={{ marginRight: "10px" }} />Ver árvore</button>
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={setopenModal}><FaPlus style={{ marginRight: "10px" }} />Novo</button>
                    {modalEditarOpen &&
                        <ModalEditarCapacidade />
                    }
                </div>
                <div className='card card-table'>
                    <h3 className='h3-demanda' style={h3Demanda}>Capacidade de Negócio da Demanda</h3>
                    <div className="table-responsive text-nowrap">
                    </div>
                    {loading ? (
                        <div className="loading-screen">
                            <div className="loader-container">
                                <div className="spinner"></div>
                            </div>
                        </div>
                    ) : (
                        <div className='tabela-geral'>
                            <GridComponent locale='pt-BR' dataSource={Capacidades} toolbar={toolbarOptions} rowTemplate={gridTemplate} allowPaging={true} allowSorting={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                                <ColumnsDirective>
                                    <ColumnDirective field='titulo' headerText='Título' width='400' textAlign='Left'></ColumnDirective>
                                    <ColumnDirective field='ativo' headerText='Ativo' width='130'></ColumnDirective>
                                    <ColumnDirective headerText='Opções' width='160'></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Page, Sort, Toolbar]} />
                            </GridComponent>
                        </div>
                    )}
                </div>
            </div >
            <Footer></Footer>
        </>
    );
}

export default CapacidadeNegocioDemanda;