import { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { FaStar, FaRegStar } from "react-icons/fa";
import { IoMdSad } from "react-icons/io";
import { IoWarningOutline, IoAlertCircleOutline } from "react-icons/io5";
import { CiDumbbell } from "react-icons/ci";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, BubbleSeries } from '@syncfusion/ej2-react-charts';

export default function Swot({ corFavorito, tab }) {
    const { AdicionarGraficoFavorito, GraficoSwotCiclo } = useContext(ApiContext)

    const theme = localStorage.getItem('theme') || 'light'

    const pointRender1 = (args) => {
        args.fill = GraficoSwotCiclo?.forca.map((item) => item.cor)[args.point.index % 10];
    };
    const pointRender2 = (args) => {
        args.fill = GraficoSwotCiclo?.fraqueza.map((item) => item.cor)[args.point.index % 10];
    };
    const pointRender3 = (args) => {
        args.fill = GraficoSwotCiclo?.oportunidade.map((item) => item.cor)[args.point.index % 10];
    };
    const pointRender4 = (args) => {
        args.fill = GraficoSwotCiclo?.ameaca.map((item) => item.cor)[args.point.index % 10];
    };

    return (
        <>
            {GraficoSwotCiclo &&
                <>
                    <h2 className="h2-bsc">
                        <button onClick={() => AdicionarGraficoFavorito("SWOT")} className='btn-grafico'>{corFavorito("SWOT") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                        <span style={{ fontWeight: '500' }}>SWOT</span>
                    </h2>
                    <div style={{ display: 'flex', gap: '50px' }}>
                        <div style={{ width: '800px', height: '400px', position: 'absolute' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '400px', height: '200px', padding: '15px 30px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><CiDumbbell style={{ fontSize: '25px', color: '#EAB308' }} /> Forças</h2>
                                </div>
                                <div style={{ width: '400px', height: '200px', padding: '15px 30px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><IoMdSad style={{ fontSize: '25px', color: '#F97316' }} /> Fraquezas</h2>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '400px', height: '200px', padding: '15px 30px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><IoAlertCircleOutline style={{ fontSize: '25px', color: '#0077E6' }} /> Oportunidades</h2>
                                </div>
                                <div style={{ width: '400px', height: '200px', padding: '15px 30px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><IoWarningOutline style={{ fontSize: '25px', color: '#EF4444' }} /> Ameaças</h2>
                                </div>
                            </div>
                        </div>
                        <div className='control-section'>
                            <div style={{ display: 'flex' }}>
                                <ChartComponent id={`${tab}-swot-1`} pointRender={pointRender1} background='#FEFCE833' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200'>
                                    <Inject services={[BubbleSeries]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective dataSource={GraficoSwotCiclo?.forca} type='Bubble' minRadius={3} maxRadius={8} border={{ width: 2 }} xName='x' yName='y' />
                                    </SeriesCollectionDirective>
                                </ChartComponent>

                                <ChartComponent id={`${tab}-swot-2`} pointRender={pointRender2} background='#FFF7ED33' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200'>
                                    <Inject services={[BubbleSeries]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective dataSource={GraficoSwotCiclo?.fraqueza} type='Bubble' minRadius={3} maxRadius={8} border={{ width: 2 }} xName='x' yName='y' />
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <ChartComponent id={`${tab}-swot-3`} pointRender={pointRender3} background='#DBF4FF33' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200'>
                                    <Inject services={[BubbleSeries]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective dataSource={GraficoSwotCiclo?.oportunidade} type='Bubble' minRadius={3} maxRadius={8} border={{ width: 2 }} xName='x' yName='y' />
                                    </SeriesCollectionDirective>
                                </ChartComponent>

                                <ChartComponent id={`${tab}-swot-4`} pointRender={pointRender4} background='#FEF2F233' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200'>
                                    <Inject services={[BubbleSeries]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective dataSource={GraficoSwotCiclo?.ameaca} type='Bubble' minRadius={3} maxRadius={8} border={{ width: 2 }} xName='x' yName='y' />
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>
                        </div>
                        <div>
                            {GraficoSwotCiclo.legendas && GraficoSwotCiclo.legendas.length > 0 &&
                                GraficoSwotCiclo?.legendas.map((item, index) => {
                                    return (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
                                            <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: `${item.cor}` }}></div>
                                            <div className='span-bsc'>{item.legenda}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}