import '../../App.css';
import React, { useContext, useState, useEffect } from "react";
import { FaTimes } from 'react-icons/fa';
import { Form, Row, Col } from 'react-bootstrap';
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

function ModalEditarCapacidade() {

    const { setModalEditar, DemandaAtual, EditarCapacidade, Capacidades,
        ListaSistemaCapacidade, SistemaCapacidade, Sistemas, EdicaoCapacidade, setEdicaoCapacidade, Areas, ListaArea, Pessoas, ListaPessoa } = useContext(ApiContext)
    const [KeyPai, setKeyPai] = useState(EdicaoCapacidade?.capacidadeNegocioDemandaPai.key)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [areaKey, setAreaKey] = useState(EdicaoCapacidade?.area !== null ? EdicaoCapacidade?.area.key : "")
    const [arquitetoKey, setArquitetoKey] = useState(EdicaoCapacidade?.arquiteto !== null ? EdicaoCapacidade?.arquiteto.key : "")
    const [pessoaKey, setPessoaKey] = useState(EdicaoCapacidade?.pessoa !== null ? EdicaoCapacidade?.pessoa.key : "")

    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = (event) => {
        setEdicaoCapacidade({ ...EdicaoCapacidade, [event.target.name]: event.target.value })
    }

    const editarItensDemanda = () => {
        EditarCapacidade(DemandaAtual.chaveCadastro, KeyPai, selectedValues, areaKey, pessoaKey, arquitetoKey)
            .then(() => {
                setModalEditar(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (SistemaCapacidade) {
            const initialSelectedValues = SistemaCapacidade.map(sistema => sistema.chave);
            setSelectedValues(initialSelectedValues);
        }
    }, [SistemaCapacidade]);

    useEffect(() => {
        ListaSistemaCapacidade()
        ListaPessoa()
        ListaArea()
    }, [])

    let fields = { text: 'descricao', value: 'chave' };

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <div>
                        <h1 className="h1-modal">Editar cadastro</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setModalEditar(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content">
                    <form className="mb-3">
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Título</Form.Label>
                                <Form.Control maxLength={100} defaultValue={EdicaoCapacidade.titulo} onChange={handleChange} name="titulo" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col xs={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nível</Form.Label>
                                    <Form.Control maxLength={100} defaultValue={EdicaoCapacidade.nivel} onChange={handleChange} name="nivel" />
                                </Form.Group>
                            </Col>
                            <Col xs={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Capacidade de Negócio Pai</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={(e) => setKeyPai(e.target.value)} name="capacidadeNegocioDemandaPaiId">
                                        <option key={0} value="0">Selecione</option>
                                        {Capacidades?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chaveCadastro} selected={item.chaveCadastro == EdicaoCapacidade.capacidadeNegocioDemandaPai.key ? true : false}>
                                                    {item.descricao}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={5}>
                                <Form.Label>Área</Form.Label>
                                <Form.Select onChange={(e) => setAreaKey(e.target.value)} name="areaKey">
                                    <option value="0">Selecione</option>
                                    {Areas &&
                                        Areas?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave} selected={EdicaoCapacidade.area !== null && item.chave == EdicaoCapacidade.area.key ? true : false}>
                                                    {item.descricao}
                                                </option>
                                            )
                                        })}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} defaultValue={EdicaoCapacidade?.descricao} name="descricao" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Relevância</Form.Label>
                                    <Form.Select aria-label="Default select example" value={EdicaoCapacidade.relevancia} onChange={handleChange} name="relevancia">
                                        <option value="">Selecione</option>
                                        <option value="Habilitação de negócios">Habilitação de negócios</option>
                                        <option value="Evolução de Negócios">Evolução de Negócios</option>
                                        <option value="Operação de negócios">Operação de negócios</option>
                                        <option value="Missão crítica">Missão crítica</option>
                                        <option value="Desconhecido">Desconhecido</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <div className='control-pane' style={{ marginTop: '5px' }}>
                                    <div id="multisection" className='control-section'>
                                        <div id="multidefault">
                                            <div className="control-styles">
                                                <div>
                                                    <label className="modal-label">Sistemas:</label>
                                                    <MultiSelectComponent
                                                        mode="Box"
                                                        id="mtselement"
                                                        dataSource={Sistemas}
                                                        fields={fields}
                                                        value={selectedValues}
                                                        placeholder="Selecione os Sistemas"
                                                        allowFiltering={false}
                                                        popupHeight="200px"
                                                        change={(e) => setSelectedValues(e.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Business Owner</Form.Label>
                                <Form.Select onChange={(e) => setPessoaKey(e.target.value)} name="pessoaKey">
                                    <option value="0">Selecione</option>
                                    {Pessoas &&
                                        Pessoas?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave} selected={EdicaoCapacidade?.pessoa !== null && item.chave == EdicaoCapacidade?.pessoa.key ? true : false}>
                                                    {item.nome}
                                                </option>
                                            )
                                        })}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Arquiteto corporativo</Form.Label>
                                <Form.Select onChange={(e) => setArquitetoKey(e.target.value)} name="arquitetoKey">
                                    <option value="0">Selecione</option>
                                    {Pessoas &&
                                        Pessoas?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave} selected={EdicaoCapacidade?.arquiteto !== null && item.chave == EdicaoCapacidade?.arquiteto.key ? true : false}>
                                                    {item.nome}
                                                </option>
                                            )
                                        })}
                                </Form.Select>
                            </Col>
                        </Row>


                        {/* <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <input type="checkbox" label=" Ativo" className="toggleBtn" defaultChecked={DemandaAtual.ativo} onChange={(e) => setStatus(e.target.checked)} />
                            <p className='modal-status'>Ativo</p>
                        </div> */}
                    </form>
                </div>
                <div className="footer-modal">
                    <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditar(false)}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => editarItensDemanda()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarCapacidade