import { useContext } from 'react';
import { FaStar, FaRegStar } from "react-icons/fa";
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, PieSeries, AccumulationTooltip, AccumulationDataLabel } from '@syncfusion/ej2-react-charts';
import { ApiContext } from '../../context/ApiContext';

export default function Perspectivas({ corFavorito, DiretrizEstrategica, tab }) {
    const { AdicionarGraficoFavorito } = useContext(ApiContext)

    const retornaDescricao = (descricao) => {
        switch (descricao) {
            case "PERSPECTIVA FINANCEIRA":
                return "Financeira"
            case "PERSPECTIVA COOPERADO & CLIENTE":
                return "Cooperado e cliente"
            case "PERSPECTIVA INTERNA":
                return "Interna"
            case "APRENDIZADO & CRESCIMENTO":
                return "Aprendizado e crescimento"
            default:
                break;
        }
    }

    const onPointRender = (args) => {
        if (args.point.x == 'PERSPECTIVA FINANCEIRA') {
            args.fill = '#BFDE94';
        } else if (args.point.x == 'PERSPECTIVA COOPERADO & CLIENTE') {
            args.fill = '#9FCB61';
        } else if (args.point.x == 'PERSPECTIVA INTERNA') {
            args.fill = '#388933';
        } else if (args.point.x == 'APRENDIZADO & CRESCIMENTO') {
            args.fill = '#0F6631';
        } else {
            args.fill = '#7f84e8';
        }
    };

    const retornaCor = (descricao) => {
        switch (descricao) {
            case "PERSPECTIVA FINANCEIRA":
                return "#BFDE94"
            case "PERSPECTIVA COOPERADO & CLIENTE":
                return "#9FCB61"
            case "PERSPECTIVA INTERNA":
                return "#388933"
            case "APRENDIZADO & CRESCIMENTO":
                return "#0F6631"
            default:
                break;
        }
    }

    return (
        <>
            <h2 className="h2-bsc">
                <button onClick={() => AdicionarGraficoFavorito("Perspectivas")} className='btn-grafico'>{corFavorito("Perspectivas") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                <span style={{ fontWeight: '500' }}>Perspectivas</span>
            </h2>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start', marginTop: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <AccumulationChartComponent
                        pointRender={onPointRender}
                        tooltip={{ enable: true }}
                        width='700'
                        height='270'
                        center={{ y: '36%' }}
                        id={`${tab}-perspectivas`}
                    >
                        <Inject services={[PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
                        <AccumulationSeriesCollectionDirective>
                            <AccumulationSeriesDirective
                                dataSource={DiretrizEstrategica}
                                yName='pesoPorcentagem'
                                xName='descricao'
                                type='Pie'
                                pointColorMapping='fill'
                            >
                            </AccumulationSeriesDirective>
                        </AccumulationSeriesCollectionDirective>
                    </AccumulationChartComponent>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className='row'>
                        {DiretrizEstrategica?.map((item, index) => {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }} className='col-lg-6'>
                                    <div style={{ width: '12px', height: '12px', marginRight: '8px', backgroundColor: retornaCor(item.descricao), borderRadius: '2px' }} /> <span style={{ marginRight: '10px', color: '#A1A1AA' }}>{item.pesoPorcentagem}%</span>
                                    <span className='span-bsc'> {retornaDescricao(item.descricao)}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}